<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Oferta</h2>
            <h4>
              Nasza oferta obejmuje kompleksową obsługę firm w następującym
              zakresie:
            </h4>
          </div>

          <img src="images/of.jpg" width="237" height="158" style="float:right">
          <ul>
            <li>prowadzenie ksiąg handlowych,</li>
            <li>prowadzenie podatkowych ksiąg przychodów i rozchodów,</li>
            <li>
              prowadzenie ewidencji przychodów&nbsp;dla celów zryczałtowanego
              podatku dochodowego od osób fizycznych,
            </li>
            <li>
              prowadzenie ewidencji dla celów rozliczenia podatku od
              towarów i usług (VAT),
            </li>
            <li>prowadzenie ewidencji środków trwałych i wyposażenia,</li>
            <li>sporządzanie deklaracji podatkowych,</li>
            <li>wyprowadzanie zaległości podatkowych,</li>
            <li>sporządzania sprawozdań finansowych,</li>
            <li>nadzoru księgowego w siedzibie Klienta,</li>
            <li>konsultacji i doradztwa w sprawach prowadzenia firmy,</li>
            <li>pomocy przy zakładaniu działalności gospodarczej,</li>
            <li>pomocy w wyborze najkorzystniejszej formy opodatkowania,</li>
            <li>
              porady w zakresie optymalizacji kosztów oraz zmniejszania
              obciążeń podatkowych,
            </li>
            <li>
              sporządzania list płac, sporządzania kartotek wynagrodzeń
              pracowniczych,
            </li>
            <li>rozliczania podatku dochodowego od pracowników,</li>
            <li>
              obsługi kadrowej, sporządzenia deklaracji ZUS i ich wysyłki
              elektronicznej,
            </li>
            <li>innych prac z zakresu księgowości i finansów,</li>
            <li>
              reprezentowania Klienta przed Urzędem Skarbowym, Zakładem
              Ubezpieczeń Społecznych i innymi instytucjami państwowymi i
              samorządowymi,
            </li>
            <li>przechowywania i archiwizowania dokumentów </li>
          </ul>
          <div class="headline style-3">
            <h4>
              Świadczymy również usługi dla osób nie prowadzących działalności
              gospodarczej w zakresie:
            </h4>
          </div>
          <ul>
            <li>
              rozliczenia rocznego z osiągniętych dochodów (PIT-37,
              PIT-36, PIT-28),
            </li>
            <li>
              sporządzania wniosków o zwrot niektórych wydatków związanych
              z budownictwem mieszkaniowym (VZM-1).
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: 'Oferta - Biuro rachunkowe Poznań "STORNO"'
  }
}
</script>
